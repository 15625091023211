import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { Row, Col } from 'antd';
import Img from "gatsby-image";

const LabNotesFront = () => {
    const data = useStaticQuery(graphql`
    {
        allAirtable(filter: {table: {eq: "lab_notes"}}, sort: {fields: data___date, order: DESC}) {
          edges {
            node {
              data {
                slug
                title
                image {
                  id
                  localFiles {
                    childImageSharp {
                      fluid(jpegProgressive: true, maxWidth: 368, maxHeight: 199) {
                        ...GatsbyImageSharpFluid
                      }
                    }
                  }
                }
              }
            }
          }
        }
        imageSharp(id: {eq: "c8261f59-2db6-57bb-867e-46eceaaa8485"}) {
          id
          gatsbyImageData(
            layout: FIXED
            width: 200
            height: 200
            transformOptions: {cropFocus: CENTER}
          )
        }
      }      
    `)
    const labNotes = data.allAirtable.edges.slice(0, 4)
    const defImage = data.imageSharp.gatsbyImageData
return (
    <Row id="notes-front" style={{marginBottom: `24px`}} gutter={[32, 16]}>
    {
        labNotes.map(labnote =>  {
                const focusPath = `/labnotes/${labnote.node.data.slug}`;
                const noteImage = labnote.node.data.image ? labnote.node.data.image.localFiles[0].childImageSharp.fluid : defImage
                return (
                    <Col xs={24} lg={12} xl={6} key={labnote.node.data.slug} className='labnote'>
                    <Link to={focusPath}>
                        <Img fluid={noteImage} style={{borderRadius: `50%`, height: `200px`, width: `200px`, marginInline: `auto`, marginBottom: `1rem`}} />
                        <h3 style={{textAlign: `center`}}>{labnote.node.data.title}</h3>
                    </Link>
                    </Col>
                    )
            })}
    </Row>
)}
    
    export default LabNotesFront