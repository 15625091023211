import * as React from "react";
const SvgKrkngSun = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 345.472 244.921"
    {...props}
  >
    <defs>
      <marker
        id="krkng_sun_svg__e"
        orient="auto"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
      >
        <path
          d="m0 0 5-5-17.5 5L5 5Z"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "context-stroke",
            strokeWidth: "1pt",
          }}
          transform="translate(1.2)scale(.2)"
        />
      </marker>
      <marker
        id="krkng_sun_svg__d"
        orient="auto"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
      >
        <path
          d="M8.719 4.034-2.207.016 8.719-4.002c-1.746 2.372-1.736 5.618 0 8.036z"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "context-stroke",
            strokeWidth: 0.625,
            strokeLinejoin: "round",
          }}
          transform="translate(-.69)scale(.3)"
        />
      </marker>
      <marker
        id="krkng_sun_svg__c"
        orient="auto"
        refX={0}
        refY={0}
        style={{
          overflow: "visible",
        }}
      >
        <path
          d="M8.719 4.034-2.207.016 8.719-4.002c-1.746 2.372-1.736 5.618 0 8.036z"
          style={{
            fill: "context-stroke",
            fillRule: "evenodd",
            stroke: "context-stroke",
            strokeWidth: 0.625,
            strokeLinejoin: "round",
          }}
          transform="translate(-.69)scale(.3)"
        />
      </marker>
      <path
        id="krkng_sun_svg__a"
        d="M2066.253 177.889h650.539V897.62h-650.539z"
      />
      <path
        id="krkng_sun_svg__f"
        d="M2023.734 245.65H2566.5v447.347h-542.766z"
      />
      <path
        id="krkng_sun_svg__g"
        d="M1940.524 466.346h850.028v1344.027h-850.028z"
      />
    </defs>
    <g
      style={{
        display: "inline",
        opacity: 1,
      }}
    >
      <path
        d="M249.466 98.197a15.419 16.046 11.031 0 0-3.454.216 15.419 16.046 11.031 0 0-12.833 18.532 15.419 16.046 11.031 0 0 .16.844l29.293-9.87-27.305 14.942a15.419 16.046 11.031 0 0 15.522 7.124 15.419 16.046 11.031 0 0 12.833-18.533 15.419 16.046 11.031 0 0-14.216-13.255zm-14.139 24.664a15.419 16.046 11.031 0 1-1.987-5.072l-121.258 40.858a69.2 69.2 0 0 1 9.859 26.263zm-113.386 62.05L7.714 247.418a69.22 69.22 0 0 0 45.833 17.402 69.224 69.224 0 0 0 69.224-69.224 69 69 0 0 0-.83-10.687ZM7.714 247.418a69.2 69.2 0 0 1-11.967-13.787l-24.035-1.201 1.346-26.939-54.467 18.353 13.71 64.842zm-34.656-41.927 11.552-3.892a69 69 0 0 1-.288-6.003 69.2 69.2 0 0 1 5.54-27.019l-14.923-.746zm16.804-36.914 13.887.694-1.315 26.322 109.648-36.947a69.22 69.22 0 0 0-58.535-32.274 69.22 69.22 0 0 0-63.685 42.205Zm12.572 27.016L-15.39 201.6a69.2 69.2 0 0 0 11.137 32.032l4.774.238z"
        style={{
          opacity: 1,
          fill: "#ffffad",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "#000",
          strokeWidth: 0.173721,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="M249.466 98.197a15.419 16.046 11.031 0 0-3.454.216 15.419 16.046 11.031 0 0-12.833 18.532 15.419 16.046 11.031 0 0 .16.844l29.293-9.87-27.305 14.942a15.419 16.046 11.031 0 0 15.522 7.124 15.419 16.046 11.031 0 0 12.833-18.533 15.419 16.046 11.031 0 0-14.216-13.255zm-14.139 24.664a15.419 16.046 11.031 0 1-1.987-5.072l-121.258 40.858a69.2 69.2 0 0 1 9.859 26.263zm-113.386 62.05L7.714 247.418a69.22 69.22 0 0 0 45.833 17.402 69.224 69.224 0 0 0 69.224-69.224 69 69 0 0 0-.83-10.687ZM7.714 247.418a69.2 69.2 0 0 1-11.967-13.787l-24.035-1.201 1.346-26.939-54.467 18.353 13.71 64.842zm-34.656-41.927 11.552-3.892a69 69 0 0 1-.288-6.003 69.2 69.2 0 0 1 5.54-27.019l-14.923-.746zm16.804-36.914 13.887.694-1.315 26.322 109.648-36.947a69.22 69.22 0 0 0-58.535-32.274 69.22 69.22 0 0 0-63.685 42.205Zm12.572 27.016L-15.39 201.6a69.2 69.2 0 0 0 11.137 32.032l4.774.238z"
        style={{
          opacity: 1,
          fill: "#ffffad",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "#000",
          strokeWidth: 0.173721,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 1,
        }}
        transform="translate(81.51 -98.089)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <path
        d="m8.193 280.016 171.78-153.468"
        style={{
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
          markerStart: "url(#krkng_sun_svg__c)",
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m37.717 192.183 57.156-51.061"
        style={{
          display: "inline",
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
          markerStart: "url(#krkng_sun_svg__d)",
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m-66.154 245.903 64.429-57.798"
        style={{
          display: "inline",
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
          markerStart: "url(#krkng_sun_svg__e)",
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="M-25.06 160.471 137.374 342.29"
        style={{
          fill: "#b380ff",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m21.524 224.502 21.68 24.266"
        style={{
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 0.21649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m59.563 258.629 63.215-56.477"
        style={{
          opacity: 0.636862,
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 0.265449,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="M-146.245 196.766h12.583v4.473h-12.583z"
        style={{
          opacity: 1,
          fill: "#ff782f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "#b9b4ff",
          strokeWidth: 0.21649,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="rotate(-41.761 -87.812 -155.88)"
      />
      <text
        xmlSpace="preserve"
        x={-314.95}
        y={-116.192}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 100,
          fontStretch: "normal",
          fontSize: "42.3332px",
          lineHeight: 1.25,
          fontFamily: "Manjari",
          InkscapeFontSpecification: "&quot",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.264583,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m8.193 280.016 171.78-153.468"
        style={{
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
          markerStart: "url(#krkng_sun_svg__c)",
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m37.717 192.183 57.156-51.061"
        style={{
          display: "inline",
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
          markerStart: "url(#krkng_sun_svg__d)",
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m-66.154 245.903 64.429-57.798"
        style={{
          display: "inline",
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
          markerStart: "url(#krkng_sun_svg__e)",
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="M-25.06 160.471 137.374 342.29"
        style={{
          fill: "#b380ff",
          stroke: "#b9b4ff",
          strokeWidth: 2.1649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m21.524 224.502 21.68 24.266"
        style={{
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 0.21649,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="m59.563 258.629 63.215-56.477"
        style={{
          opacity: 0.636862,
          fill: "none",
          stroke: "#b9b4ff",
          strokeWidth: 0.265449,
          strokeLinecap: "butt",
          strokeLinejoin: "miter",
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="translate(81.51 -98.089)"
      />
      <path
        d="M-146.245 196.766h12.583v4.473h-12.583z"
        style={{
          opacity: 1,
          fill: "#ff782f",
          fillOpacity: 1,
          fillRule: "nonzero",
          stroke: "#b9b4ff",
          strokeWidth: 0.21649,
          strokeMiterlimit: 4,
          strokeDasharray: "none",
          strokeOpacity: 0.8,
        }}
        transform="rotate(-41.761 -87.812 -155.88)"
      />
    </g>
    <g
      style={{
        display: "inline",
      }}
    >
      <text
        xmlSpace="preserve"
        x={-119.128}
        y={201.807}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 100,
          fontStretch: "normal",
          fontSize: "34.6384px",
          lineHeight: 1.25,
          fontFamily: "Manjari",
          InkscapeFontSpecification: "&quot",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          display: "inline",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.21649,
        }}
        transform="rotate(-41.714 -87.97 -156.012)"
      >
        <tspan
          x={-119.128}
          y={201.807}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 100,
            fontStretch: "normal",
            fontSize: "34.6384px",
            fontFamily: "Manjari",
            InkscapeFontSpecification: "&quot",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            strokeWidth: 0.21649,
          }}
        >
          {"KARAKUNG"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={222.563}
        y={119.516}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 100,
          fontStretch: "normal",
          fontSize: "34.6384px",
          lineHeight: 1.25,
          fontFamily: "Manjari",
          InkscapeFontSpecification: "&quot",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          display: "inline",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.21649,
        }}
        transform="rotate(48.381 149.932 41.68)"
      >
        <tspan
          x={222.563}
          y={119.516}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 100,
            fontStretch: "normal",
            fontSize: "34.6384px",
            fontFamily: "Manjari",
            InkscapeFontSpecification: "&quot",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            strokeWidth: 0.21649,
          }}
        >
          {"REEF"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={-119.128}
        y={201.807}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 100,
          fontStretch: "normal",
          fontSize: "34.6384px",
          lineHeight: 1.25,
          fontFamily: "Manjari",
          InkscapeFontSpecification: "&quot",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          display: "inline",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.21649,
        }}
        transform="rotate(-41.714 -87.97 -156.012)"
      >
        <tspan
          x={-119.128}
          y={201.807}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 100,
            fontStretch: "normal",
            fontSize: "34.6384px",
            fontFamily: "Manjari",
            InkscapeFontSpecification: "&quot",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            strokeWidth: 0.21649,
          }}
        >
          {"KARAKUNG"}
        </tspan>
      </text>
      <text
        xmlSpace="preserve"
        x={222.563}
        y={119.516}
        style={{
          fontStyle: "normal",
          fontVariant: "normal",
          fontWeight: 100,
          fontStretch: "normal",
          fontSize: "34.6384px",
          lineHeight: 1.25,
          fontFamily: "Manjari",
          InkscapeFontSpecification: "&quot",
          fontVariantLigatures: "normal",
          fontVariantCaps: "normal",
          fontVariantNumeric: "normal",
          fontVariantEastAsian: "normal",
          display: "inline",
          fill: "#000",
          fillOpacity: 1,
          stroke: "none",
          strokeWidth: 0.21649,
        }}
        transform="rotate(48.381 149.932 41.68)"
      >
        <tspan
          x={222.563}
          y={119.516}
          style={{
            fontStyle: "normal",
            fontVariant: "normal",
            fontWeight: 100,
            fontStretch: "normal",
            fontSize: "34.6384px",
            fontFamily: "Manjari",
            InkscapeFontSpecification: "&quot",
            fontVariantLigatures: "normal",
            fontVariantCaps: "normal",
            fontVariantNumeric: "normal",
            fontVariantEastAsian: "normal",
            strokeWidth: 0.21649,
          }}
        >
          {"REEF"}
        </tspan>
      </text>
    </g>
  </svg>
);
export default SvgKrkngSun;
