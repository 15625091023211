import React from "react"
import {  Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image";
import { Row, Col, Button, Typography } from 'antd';
import SvgKrkngSun from "../../components/svg/KrkngSun.jsx";
import SvgKrkngBkgrnd from "../../components/svg/KrkngBkgrnd.jsx";


const { Paragraph } = Typography;

const Krkng = () => {
  
  return (
    <div>
        <div className="fullwidth krkng" style={{ backgroundImage: `url(/reef.jpg)`, backgroundSize: `cover`, padding: `2rem`, marginBlock: `2rem` }}>
            <Row>
                <Col xs={24} sm={24} lg={12} style={{ display: `flex`, justifyContent: `center` }} >
                    <SvgKrkngSun style={{ aspectRatio: `1`, padding: `1vw` }} />
                </Col>

                <Col xs ={24} sm={24} lg={12} style={{ display: `flex`, justifyContent: `center`, flexDirection: `column` }}>
                    <h2>KARAKUNG REEF is our home base.</h2>
                    <p>a small plot of land in West Philly which contains:</p>
                    <ul>
                        <li>A tiny house and meeting space.</li>
                        <li>A shipping container housing a lab, power and storage.</li>
                        <li>A latent community garden.</li>
                        <li><Link to="karakung" style={{ marginLeft: `auto`}}>
                        <span style={{fontSize:`4rem`, lineHeight: `1`}}>➱</span>
                    </Link></li>
                    </ul>
                    
                </Col>
            </Row>
        </div>
    </div>
  )
}

export default Krkng
