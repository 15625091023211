import React from "react"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image";
import { Button, Typography } from 'antd';

const { Paragraph } = Typography;

const Holobiont = () => {
  
  return (
    <div className="holobiont-banner">
      <div className="holobiont-banner__inner">
        <h2>Why Holobiont</h2>
        <p>
        Holobiont is a term used in the biological sciences that looks beyond individual, isolated organisms toward the whole network of dynamic, interconnected elements that make life and survivability possible. The term is derived from the Ancient Greek word hólos (for “whole”) and the word biont (for “unit of life”). 
        </p>
        <Link to='/about/#why-holobiont'>
          <Button type="default" shape="round" >  MORE  </Button>
        </Link>
      </div>
    </div>
  )
}

export default Holobiont
