import React from "react";
import Helmet from "react-helmet";
import { useStaticQuery, graphql, Link } from "gatsby";
import Layout from "../layout";
import SEO from "../components/SEO/SEO";
import config from "../../data/SiteConfig";
import FrontHero from "../components/FrontHero";
import Projects from "../components/Projects";
import Events from "../components/Events";
import Holobiont from "../components/holobiont";
import LabNotesFront from "../components/LabnotesFront";
import Krkng from "../components/Krkng"

const Index = () => {
  const pageQuery = useStaticQuery(graphql`
  query MyQuery {
    airtable(data: {slug: {eq: "front"}}) {
      data {
        title
        slug
        date
        status
        author
        image {
          localFiles {
            childImageSharp {
              fixed {
                src
              }
            }
          }
        }
        postMarkdown {
          childMarkdownRemark {
            html
          }
        }
      }
    }
  }
  `)
  const pageContent = pageQuery.airtable.data.postMarkdown.childMarkdownRemark.html
  return (
      <Layout>
        <div className="index-container" > 
          <Helmet title={config.siteTitle} />
          <SEO />
          <FrontHero/>
          <div dangerouslySetInnerHTML={{ __html: pageContent}} className='front-content' />
          <hr className="zigzag" />
          <Events />
          <hr className="zigzag" />
          <Link to="/projects">
            <h2 style={{textAlign: `center`}} className="sub-header">PROJECTS</h2>
          </Link>
            <Projects />
          <Krkng />
          <Link to="/labnotes">
            <h2 style={{textAlign: `center`}} className="sub-header">LAB NOTES</h2>
          </Link>
          <LabNotesFront />
          <hr className="zigzag" /> 
          <Holobiont />
        </div>
      </Layout>
    );
  
}

export default Index;