import React from "react";

const FrontHero = () => {
  return (
    <div>
      <div className="front_hero fullwidth" style={{
        backgroundImage: `url(front_bright.png)`,
      }}>
          <div className="front_hero_inner">
              <h1>HOLOBIONT LAB</h1>
              <h3>a climate change community impact laboratory</h3>
          </div>
      </div>
    </div>
  );
}
  
  export default FrontHero